import blank from '../Images/Blank.jpg'
//Gym
import image1 from "../Images/Categories/GYM/Products/p-image1.png";
import image2 from "../Images/Categories/GYM/Products/p-image2.png";
import image3 from "../Images/Categories/GYM/Products/p-image3.png";
import image4 from "../Images/Categories/GYM/Products/p-image4.png";
import image5 from "../Images/Categories/GYM/Products/p-image5.png";
import image6 from "../Images/Categories/GYM/Products/p-image6.png";
import image7 from "../Images/Categories/GYM/Products/p-image7.png";
import image8 from "../Images/Categories/GYM/Products/p-image8.png";
import gymimage1 from '../Images/Categories/GYM/cards/image1.png';
import gymimage2 from '../Images/Categories/GYM/cards/image2.png';
import gymimage3 from '../Images/Categories/GYM/cards/image3.png';
import gymimage4 from '../Images/Categories/GYM/cards/image4.png';
import GyamBrand1 from '../Images/Categories/GYM/brands/brand1.png';
import GyamBrand2 from '../Images/Categories/GYM/brands/brand2.png';
import GyamBrand3 from '../Images/Categories/GYM/brands/brand3.png';
import gymMainImg from '../Images/Categories/hero_image.png'
//Furniture
import furMainImg from '../Images/Categories/Furnitures/Hero Img.png'
import furimage1 from '../Images/Categories/Furnitures/cards/img1.png'
import furimage2 from '../Images/Categories/Furnitures/cards/img2.png'
import furimage3 from '../Images/Categories/Furnitures/cards/img3.png'
import furimage4 from '../Images/Categories/Furnitures/cards/img4.png'
import furBrand1 from '../Images/Categories/Furnitures/brands/brand1.png';
import furBrand2 from '../Images/Categories/Furnitures/brands/brand2.png';
import furBrand3 from '../Images/Categories/Furnitures/brands/brand3.png';
import furImg1 from "../Images/Categories/Furnitures/Products/f-p-image1.png";
import furImg2 from "../Images/Categories/Furnitures/Products/f-p-image2.png";
import furImg3 from "../Images/Categories/Furnitures/Products/f-p-image3.png";
import furImg4 from "../Images/Categories/Furnitures/Products/f-p-image4.png";
import furImg5 from "../Images/Categories/Furnitures/Products/f-p-image5.png";
import furImg6 from "../Images/Categories/Furnitures/Products/f-p-image6.png";
import furImg7 from "../Images/Categories/Furnitures/Products/f-p-image7.png";
import furImg8 from "../Images/Categories/Furnitures/Products/f-p-image8.png";
// Appliances
import appMainImg from '../Images/Categories/Appliances/Hero Img.png'
import appImage1 from '../Images/Categories/Appliances/cards/img1.png'
import appImage2 from '../Images/Categories/Appliances/cards/img2.png'
import appImage3 from '../Images/Categories/Appliances/cards/img3.png'
import appImage4 from '../Images/Categories/Appliances/cards/img4.png'
import appBrand1 from '../Images/Categories/Appliances/brands/brand1.png'
import appBrand2 from '../Images/Categories/Appliances/brands/brand2.png'
import appBrand3 from '../Images/Categories/Appliances/brands/brand3.png'
import appImg1 from "../Images/Categories/Appliances/Products/a-p-image1.png";
import appImg2 from "../Images/Categories/Appliances/Products/a-p-image2.png";
import appImg3 from "../Images/Categories/Appliances/Products/a-p-image3.png";
import appImg4 from "../Images/Categories/Appliances/Products/a-p-image4.png";
import appImg5 from "../Images/Categories/Appliances/Products/a-p-image5.png";
import appImg6 from "../Images/Categories/Appliances/Products/a-p-image6.png";
import appImg7 from "../Images/Categories/Appliances/Products/a-p-image7.png";
import appImg8 from "../Images/Categories/Appliances/Products/a-p-image8.png";
//Ceramics

//Sanitary
import sanMainImg from '../Images/Categories/Sanitary/Hero Img.png'
import sanImage1 from '../Images/Categories/Sanitary/cards/img1.png'
import sanImage2 from '../Images/Categories/Sanitary/cards/img2.png'
import sanImage3 from '../Images/Categories/Sanitary/cards/img3.png'
import sanImage4 from '../Images/Categories/Sanitary/cards/img4.png'
import sanBrand1 from '../Images/Categories/Sanitary/brands/brand1.png'
import sanBrand2 from '../Images/Categories/Sanitary/brands/brand2.png'
import sanBrand3 from '../Images/Categories/Sanitary/brands/brand3.png'
import sanImg1 from "../Images/Categories/Sanitary/Products/s-p-image1.png";
import sanImg2 from "../Images/Categories/Sanitary/Products/s-p-image2.png";
import sanImg3 from "../Images/Categories/Sanitary/Products/s-p-image3.png";
import sanImg4 from "../Images/Categories/Sanitary/Products/s-p-image4.png";
import sanImg5 from "../Images/Categories/Sanitary/Products/s-p-image5.png";
import sanImg6 from "../Images/Categories/Sanitary/Products/s-p-image6.png";
import sanImg7 from "../Images/Categories/Sanitary/Products/s-p-image7.png";
import sanImg8 from "../Images/Categories/Sanitary/Products/s-p-image8.png";

//Doors
import doMainImg from '../Images/Categories/Doors/Hero Img.png'
import doImage1 from '../Images/Categories/Doors/cards/img1.png'
import doImage2 from '../Images/Categories/Doors/cards/img2.png'
import doImage3 from '../Images/Categories/Doors/cards/img3.png'
import doImage4 from '../Images/Categories/Doors/cards/img4.png'
import doBrand1 from '../Images/Categories/Doors/brands/brand1.png'
import doBrand2 from '../Images/Categories/Doors/brands/brand2.png'
import doBrand3 from '../Images/Categories/Doors/brands/brand3.png'
import doImg1 from "../Images/Categories/Doors/Products/d-p-image1.png";
import doImg2 from "../Images/Categories/Doors/Products/d-p-image2.png";
import doImg3 from "../Images/Categories/Doors/Products/d-p-image3.png";
import doImg4 from "../Images/Categories/Doors/Products/d-p-image4.png";
import doImg5 from "../Images/Categories/Doors/Products/d-p-image5.png";
import doImg6 from "../Images/Categories/Doors/Products/d-p-image6.png";
import doImg7 from "../Images/Categories/Doors/Products/d-p-image7.png";
import doImg8 from "../Images/Categories/Doors/Products/d-p-image8.png";


const gymData = {
mainData: [
  {
    id:1,
    title1:'GYM',
    title2:'EQUIPMENTS',
    subtitle:"WHERE QUALITY MEETS THE BEST COST, DELIVERING AN UNMATCHED EXPERIENCE.",
    brandTitle:'some of our brands',
    hlt1:'TEXT EXAMPLE',
    hlt2:'TEXT EXAMPLE',
    hlt3:'TEXT EXAMPLE',
    hrt1:'TEXT EXAMPLE',
    hrt2:'TEXT EXAMPLE',
    hrt3:'TEXT EXAMPLE',
    catalogue:'https://drive.google.com/uc?export=download&id=1QkOafwmfP4ymBhQ4igfN8pMf77Lxxoff',
    categorie:'Gym',
    imgMain:gymMainImg,
    image1:gymimage1,
    image2:gymimage2,
    image3:gymimage3,
    image4:gymimage4,
    resons1:'We stand out as your premier choice.',
    resons2:'Our commitment to excellence is reflected in the quality and innovation of our products.',
    resons3:'Focus on durability, performance, and customer satisfaction, we provide cutting-edge solutions.',
    resons4:'unmatched reliability,and top-notch service.',
    brandImage1:GyamBrand1,
    brandImage2:GyamBrand2,
    brandImage3:GyamBrand3,
    items: [
      {
        image: image1,
        review:
          "fundamental fitness tool for indoor running or walking. Featuring a moving belt, it facilitates cardiovascular exercise, improving endurance and calorie burning. Essential for gyms or homes, treadmills offer adjustable settings for speed and incline, providing a versatile means to achieve fitness goals.",
        footer: ' TREADMILL',
        sFooter: 'Cardio Endurance'
      },
      {
        image: image2,
        review: ' new and innovative plate loaded machine, which makes hip-thrusting safe, easy and fun, on a minimal footprint. Booty Builder Platinum provides the ultimate glute workout with its patented raised feet design allowing for a deeper range of movement and constant resistance for better results.',
        footer: 'BootyBuilder',
        sFooter: 'Glute Sculpting'
      },
      {
        image: image3,
        review: ' robust fitness apparatus offering versatile strength training. With modular components, it provides a full-body workout, promoting muscle development and endurance. Precision engineering and ergonomic design make it cornerstone for effective strength conditioning.­­',
        footer: 'TUFFSTUFF',
        sFooter: "440 lb/200 kg"
      },
      {
        image: image4,
        review: 'specialized fitness machine for effective upper body workouts. Designed for latissimus dorsi targeting, it offers adjustable resistance and a smooth pulley system, ensuring a controlled and impactful exercise experience. Achieve a sculpted back and improved strength with FlexLift Lat Pulldown.­­',
        footer: 'FlexLift',
        sFooter: "Back Sculpting"
      },
      {
        image: image5,
        review: 'cutting-edge indoor cycle engineered for precision workouts. With adjustable resistance and advanced performance metrics, it delivers an immersive cycling experience, optimizing cardiovascular fitness and leg strength. Elevate your cycling journey.­­',
        footer: 'Wattbike Cycle',
        sFooter: "Cardio Cycling"
      },
      {
        image: image6,
        review: 'versatile strength training tool, featuring ergonomic design and adjustable weights. Ideal for targeted muscle development, it enables a wide range of exercises to enhance upper and lower body strength. Elevate your fitness routine with it­­',
        footer: 'Dumbles',
        sFooter: "Strength Training"
      },
      {
        image: image7,
        review: 'versatile fitness machine designed for comprehensive strength training. With dual pulley systems, it facilitates a wide array of exercises targeting various muscle groups. Elevate your fitness journey with the durable and space-efficient.­­',
        footer: 'Body Solid Crossover',
        sFooter: "Versatile Strength"
      },
      {
        image: image8,
        review: 'comprises a range of cutting-edge cardio items designed for effective cardiovascular workouts. From treadmills to stationary bikes, these devices offer customizable settings, ensuring an engaging and heart-healthy exercise experience. Elevate your cardio routine with it for optimal fitness results.',
        footer: 'CardioGear Essentials',
        sFooter: "Cardiovascular Exercise"
      }
    ]
}],
};
const FurnitureData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'FURNITURE',
      subtitle:"Discover our diverse range of home furniture, where you can find pieces to suit all décor tastes.",
      brandTitle:'some of our clients',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'https://drive.google.com/uc?export=download&id=1eXGpH_ODoaoMqrhBeNBefowdNiJrWN0S',
      categorie:'Furniture',
      imgMain:furMainImg,
      image1:furimage1,
      image2:furimage2,
      image3:furimage3,
      image4:furimage4,
      resons1:'Exceptional durability and precision for furniture that stands the test of time.',
      resons2:'Unique, innovative styles to express your individuality through standout home décor.',
      resons3:'Expert advice and seamless experiences from selection to delivery.',
      resons4:'Competitive pricing without compromising on the excellence of our products.',
      brandImage1:furBrand1,
      brandImage2:furBrand2,
      brandImage3:furBrand3,
      items: [
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
        {
          image: furImg1,
          review:'characterized by outward armrest and backrest. It is elegant from different angles. Flexible combination which is easy to suit for different space need. ',
          footer: 'Sofa',
          sFooter: 'Living Room'
        },
      ]
  }],
  };
const AppliancesData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'APPLIANCES',
      subtitle:"UPGRADE YOUR HOME WITH OUR CUTTING-EDGE APPLIANCES, COMBINING INNOVATION, EFFICIENCY, AND STYLE FOR A MODERN LIFESTYLE EXPERIENCE.",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'https://drive.google.com/uc?export=download&id=16AIwKRY5poCVZoLyqEQlTa3s1Hn99BSK',
      imgMain:appMainImg,
      categorie:'Appliances',
      image1:appImage1,
      image2:appImage2,
      image3:appImage3,
      image4:appImage4,
      resons1:'Dependable appliances for consistent performance and lasting reliability.',
      resons2:'Explore cutting-edge designs that enhance your daily life with style and functionality.',
      resons3:'Personalized service, providing expert advice and a smooth shopping experience.',
      resons4:'Competitive prices without sacrificing the efficiency and quality of our appliances.',
      brandImage1:appBrand1,
      brandImage2:appBrand2,
      brandImage3:appBrand3,
      items: [
        {
          image: appImg1,
          review:'Self-cleaning, defrost function, multifunction oven, inside cavit with black enamelled moulded sides, a++ energy class, 71l gross capacity, 15 cooking functions, boost to rapid reach, triple glazed door, 1 shelf + 1 tray, cooling fan, inox',
          footer: 'ELECTRONIC OVEN',
          sFooter: 'KITCHEN'
        },
        {
          image: appImg2,
          review:'90 cm induction hob, Frameless, 4mm edge, Plane multi-heating circle, Sliding touch control, Oval heating, Child lock, Timer',
          footer: 'HOB',
          sFooter: 'KITCHEN'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const CeramicsData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'CERAMICS',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Ceramics',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const SanitaryData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'SANITARY',
      subtitle:"DISCOVER SUPERIOR HYGIENE AND FUNCTIONALITY WITH OUR COMPREHENSIVE RANGE OF SANITARY SOLUTIONS, DESIGNED FOR MODERN LIVING AND ULTIMATE COMFORT.",
      brandTitle:'',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'https://drive.google.com/uc?export=download&id=1VzvNGJegGeeMZ1banlXOWLZPi5xyshXw',
      categorie:'Sanitary',
      imgMain:sanMainImg,
      image1:sanImage1,
      image2:sanImage2,
      image3:sanImage3,
      image4:sanImage4,
      resons1:'High-quality sanitary ware ensuring durability and a touch of elegance to your space.',
      resons2:'Explore modern and stylish designs for sanitary solutions that enhance your bathroom aesthetics.',
      resons3:'Personalized service with expert advice for a seamless selection and installation experience.',
      resons4:'Competitive prices for sophisticated sanitary ware without compromising on quality and functionality.',
      brandImage1:'sanBrand1',
      brandImage2:'sanBrand2',
      brandImage3:'sanBrand3',
      items: [
        {
          image: sanImg1,
          review:'Upgrade your bathroom storage with our spacious, stylish sink cabinet, combining form and function for a clutter-free sanctuary.',
          footer: 'SINK CABINET',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg2,
          review:'Experience comfort and hygiene with our premium toilet seat, designed for durability, easy cleaning, and timeless elegance in your bathroom.',
          footer: 'TOILET SEAT',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg3,
          review:"Elevate your bathroom's aesthetic with our sleek and versatile sink, combining style, functionality, and durability for a refreshing upgrade.",
          footer: 'SINK',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg4,
          review:'Transform your bathroom into a luxurious retreat with our modern, elegant glass shower, offering sophistication, durability, and a refreshing escape.',
          footer: 'GLASS SHOWER',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg5,
          review:'Elevate your shower experience with our premium range of stylish and practical shower accessories, enhancing convenience and luxury in every wash.',
          footer: 'SHOWER ACCESSORIES',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg6,
          review:'Indulge in relaxation and luxury with our spacious, durable bathtub, perfect for unwinding after a long day and enhancing your bathroom oasis.',
          footer: 'BATHTUB',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg7,
          review:'Enhance your kitchen or bathroom with our sleek and reliable sink tap, combining modern design with effortless functionality and long-lasting performance.',
          footer: 'SINK TAP',
          sFooter: 'BATHROOM'
        },
        {
          image: sanImg8,
          review:'Optimize your shower space with our versatile and efficient shower angle sink, designed for convenience, style, and maximum functionality in compact areas.',
          footer: 'SHOWER ANGLE SINK',
          sFooter: 'BATHROOM'
        }
      ]
  }],
  };
const LightingData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'LIGHTING',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Lighting',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const DoorsData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'DOORS',
      subtitle:"RANSFORM YOUR SPACE WITH OUR DIVERSE RANGE OF HIGH-QUALITY DOORS, OFFERING STYLE, SECURITY, AND DURABILITY FOR EVERY ROOM IN YOUR HOME.",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'https://drive.google.com/uc?export=download&id=10LGnbz9kGRbP1YJ9iKPa9gggyGXxb5VK',
      categorie:'Doors',
      imgMain:doMainImg,
      image1:doImage1,
      image2:doImage2,
      image3:doImage3,
      image4:doImage4,
      resons1:'Sturdy doors crafted for lasting durability and security in your home.',
      resons2:"Explore a range of stylish designs to complement your home's aesthetic.",
      resons3:'Expert guidance for selecting the perfect door, ensuring a seamless installation process.',
      resons4:'Competitive pricing without sacrificing the quality and reliability of our doors.',
      brandImage1:doBrand1,
      brandImage2:"doBrand2",
      brandImage3:"doBrand3",
      items: [
        {
          image: doImg1,
          review:"Elevate your entryways with our premium single doors, offering timeless style, security, and durability for your home's first impression.",
          footer: 'SINGLE DOORS',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg2,
          review:'Transform your space with our sleek sliding doors, offering seamless functionality, modern design, and efficient use of space for any room.',
          footer: 'SLIDING DOORS',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg3,
          review:'Enhance your décor with our versatile wall frames, offering elegant design, durability, and the perfect showcase for your cherished memories.',
          footer: 'WALL FRAMES',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg4,
          review:'Elevate your entrance with our stylish double doors, combining classic design, security, and durability for a grand and inviting statement.',
          footer: 'DOUBLE DOORS',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg5,
          review:'Upgrade your entryway with our Single Plus door, offering enhanced security, style, and durability for a modern and sophisticated home entrance.',
          footer: 'SINGLE PLUS',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg6,
          review:'Maximize your space with our innovative folding door, offering versatility, functionality, and style for seamless room transitions and elegant design.',
          footer: 'FOLDING DOOR',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg7,
          review:'Upgrade your exterior with our premium Exterior Door Plus, offering enhanced security, durability, and style for a welcoming and secure entryway.',
          footer: 'EXT. DOOR PLUS',
          sFooter: 'WOODEN WORKS'
        },
        {
          image: doImg8,
          review:"Make a grand entrance with our Exterior Double Door, combining durability, security, and timeless style for your home's exterior.",
          footer: 'EXT. DOUBLE DOOR',
          sFooter: 'WOODEN WORKS'
        }
      ]
  }],
  };
const FitOutData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'FIT-OUT',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Fit-out',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const MarbleData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'MARBLE',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Marble',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const CurtainsData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'CURTAINS',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Curtains',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const AccessoriesData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'ACCESSORIES',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Accessories',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };
const MaintenanceData = {
  mainData: [
    {
      id:1,
      title1:'',
      title2:'MAINTENANCE',
      subtitle:"",
      brandTitle:'some of our brands',
      hlt1:'Text Example',
      hlt2:'Text Example',
      hlt3:'Text Example',
      hrt1:'Text Example',
      hrt2:'Text Example',
      hrt3:'Text Example',
      catalogue:'download link here',
      categorie:'Maintenance',
      imgMain:blank,
      image1:blank,
      image2:blank,
      image3:blank,
      image4:blank,
      resons1:'Text Example',
      resons2:'Text Example',
      resons3:'Text Example',
      resons4:'Text Example',
      brandImage1:blank,
      brandImage2:blank,
      brandImage3:blank,
      items: [
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        },
        {
          image: blank,
          review:'---------------------------------------------------------------------------------------------',
          footer: 'Footer',
          sFooter: 'Second Footer'
        }
      ]
  }],
  };

export {
  gymData, 
  FurnitureData,
  AppliancesData, 
  CeramicsData, 
  SanitaryData, 
  LightingData, 
  DoorsData, 
  FitOutData, 
  MarbleData, 
  CurtainsData, 
  AccessoriesData, 
  MaintenanceData
}
